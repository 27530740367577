import {ActivatedRouteSnapshot, CanActivateFn, Router} from '@angular/router'
import {inject} from '@angular/core'
import {hasPermissions, Permission, UserService} from '../services/repository/user.service'
import {Async} from '../util/async'


export const permissionGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot
) => {

  try {

    const restrictions: Permission[] = route.data.restrictedBy
    if (!restrictions) {
      return !(route?.data.disabled)
    }

    const userService = inject(UserService)
    await Async.sleepUntil(() => userService.me != null, 10000)

    const canActivate = hasPermissions(userService.me, restrictions)

    if (canActivate) {
      return true
    }
  } catch (error) {
    console.error('Error in permission guard:', error)
  }

  const router = inject(Router)
  router.navigateByUrl('forbidden').then()
  return false
}
