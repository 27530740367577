import { HostListener, Injectable, Directive } from '@angular/core'


@Directive()
export abstract class AnyCanDeactivate {

  abstract canDeactivate(): boolean

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true
    }
  }
}

@Injectable()
export abstract class CanDeactivateGuard  {
  canDeactivate(anyCanDeactivate: AnyCanDeactivate): boolean {
    if (!anyCanDeactivate) {
      return confirm('You have unsaved changes! If you leave, your changes will be lost.')
    }
    return true
  }
}
