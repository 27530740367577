import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {Permission} from './services/repository/user.service'
import {permissionGuard} from './security/permission-guard.service'
import {CanDeactivateGuard} from './util/can-deactivate.guard'

export const RECORD_ROUTE = 'record'
export const SUBMIT_ROUTE = 'submit'
export const VERIFY_ROUTE = 'verify'
export const BASEDATA_ROUTE = 'basedata'
export const EVALUATE_ROUTE = 'evaluate'

const routes: Routes = [
  {
    path: 'postlogin',
    redirectTo: '',
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: '',
    redirectTo: 'record',
    pathMatch: 'full',
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: RECORD_ROUTE, loadChildren: () => import('./record/record.module').then(m => m.RecordModule),
    canActivate: [permissionGuard],
    data: {
      displayName: 'Erfassen',
      restrictedBy: [Permission.AppUsagePermission]
    }
  },
  {
    path: SUBMIT_ROUTE, loadChildren: () => import('./submit/submit.module').then(m => m.SubmitModule),
    canActivate: [permissionGuard],
    data: {
      displayName: 'Einreichen',
      restrictedBy: [Permission.AppUsagePermission]
    }
  },
  {
    path: VERIFY_ROUTE, loadChildren: () => import('./verify/verify.module').then(m => m.VerifyModule),
    canActivate: [permissionGuard],
    data: {
      displayName: 'Prüfen',
      restrictedBy: [Permission.ProcessOtherUsersMonthsSubmittedPermission]
    }
  },
  {
    path: EVALUATE_ROUTE, loadChildren: () => import('./evaluate/evaluate.module').then(m => m.EvaluateModule),
    canActivate: [permissionGuard],
    data: {
      displayName: 'Auswerten',
      restrictedBy: [
        Permission.AppUsagePermission,
        Permission.ProcessOtherUsersTimeEntriesPermission
      ]
    }
  },
  {
    path: BASEDATA_ROUTE,
    loadChildren: () => import('./basedata/basedata.module').then(m => m.BasedataModule),
    canActivate: [permissionGuard],
    data: {
      displayName: 'Verwalten',
      restrictedBy: [
        Permission.ProcessSelectionEntitiesPermission
      ]
    }
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}



